<template>
  <div v-if="navigation">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('navigation.update')"
      :title="$t('navigation.update')"
      @submitPressed="submitPressed"
    />
    <navigation-form
      :navigation-obj="navigation"
      :action="action"
      @clearAction="action = null"
    />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import NavigationForm from "./components/NavigationForm.vue";

export default {
  components: {
    TitleBar,
    NavigationForm,
  },
  data() {
    return {
      action: null,
      navigation: null,
    };
  },
  created() {
    this.loadNavigation();
  },
  methods: {
    loadNavigation() {
      const id = this.$route.params.id;
      this.$Navigations
        .getResource({ id })
        .then((response) => {
          if (response.status === 200) {
            this.navigation = response.data;
            if(this.navigation.placement) {
              this.navigation.placement = {
                label: this.$helper.getEnumTranslation('placement', this.navigation.placement, this.$i18n.locale),
                id: this.navigation.placement
              }
            }
          }
        })
    },
    submitPressed() {
      this.action = "update";
    },
  },
};
</script>
